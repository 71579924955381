import isString from 'lodash/isString';
import { NextRouter } from 'next/router';

export const pageLinks = {
  faq: {
    it: '/domande-frequenti',
    en: '/faq',
  },
  ordersInfo: {
    it: '/ordini-e-spedizioni',
    en: '/orders-and-shipping',
  },
  returns: {
    it: '/resi-e-rimborsi',
    en: '/return-and-refund',
  },
  support: {
    it: '/supporto',
    en: '/support',
  },
  terms: {
    it: '/terms-and-conditions',
    en: '/terms-and-conditions',
  },
  privacy: {
    it: '/privacy-policy',
    en: '/privacy-policy',
  },
  selling: {
    it: '/condizioni-di-vendita',
    en: '/selling-conditions',
  },
  paymentMethods: {
    it: '/metodi-di-pagamento',
    en: '/payment-methods',
  },
  aboutUs: {
    it: '/chi-siamo',
    en: '/about-us',
  },
  socials: {
    it: '/social',
    en: '/socials',
  },
  workWithUs: {
    it: '/lavora-con-noi',
    en: '/work-with-us',
  },
  pricePolicy: {
    it: '/politica-dei-prezzi',
    en: '/price-policy',
  },
  authenticity: {
    it: '/autenticita',
    en: '/authenticity',
  },
  sneakers: {
    it: '/scarpe',
    en: '/sneakers',
  },
  sneakersMen: {
    it: '/sneakers-uomo',
    en: '/sneakers-men',
  },
  sneakersWomen: {
    it: '/sneakers-donna',
    en: '/sneakers-women',
  },
  sneakersKids: {
    it: '/sneakers-bambino',
    en: '/kids-sneakers',
  },
  design: {
    it: '/design',
    en: '/design',
  },
  interiorDesign: {
    it: '/arredamento',
    en: '/interior-design',
  },
  collectibles: {
    it: '/collezionabili',
    en: '/collectibles',
  },
  sell: {
    it: '/vendi',
    en: '/sell',
  },
  contact: {
    it: '/supporto',
    en: '/support',
  },
  news: {
    it: '/magazine',
    en: '/magazine',
  },
  newsCategory: {
    it: '/magazine/category',
    en: '/magazine/category',
  },
  shop: {
    it: '/shop',
    en: '/shop',
  },
  installmentPayments: {
    it: '/pagamenti-a-rate',
    en: '/installment-payments',
  },
  thankYou: {
    it: '/thank-you',
    en: '/thank-you',
  },
  whereWeAre: {
    it: '/magazine/limited-edition-sneaker-shop-a-roma',
    en: '/en/magazine/limited-edition-sneaker-shop-in-rome',
  },
  vintage: {
    it: '/vintage',
    en: '/vintage',
  },
};

export function getLocalizedLink(
  page: keyof typeof pageLinks,
  localeOrRouter: NextRouter | string,
): string {
  const locale: string = isString(localeOrRouter) ? localeOrRouter : localeOrRouter.locale;

  return pageLinks[page]?.[locale] as string;
}
